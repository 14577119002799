import { useEffect, useState } from 'react';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { ClientTemplateFormUser } from '../../models/ClientTemplateFormUser';
import { FormConfig } from '../../models/Form';
import { FormType } from '../../models/FormTypes';
import { Roles } from '../../models/Role';
import User from '../../models/User';
import ClientTemplateFormService from '../../services/ClientTemplateFormService';
import hasFormRole from '../../utils/FormPermissionUtils';
import { hasPermission } from './usePermissions';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';
import { ClientFormTag } from '../../models/ClientFormUser';
import { useFetchActiveClientGroups } from '../useFetchActiveClientGroups';

type UserResourcePermissions = {
  isUserArchitect: boolean;
  canMaintainResources: boolean;
  canMaintainRecords: boolean;
  canViewRecords: boolean;
};

export const getUserResourcePermissions = (templateUsers: ClientTemplateFormUser[], currentUser: User, clientId: string): UserResourcePermissions => {
  const currentResourceUser = new ClientTemplateFormService(clientId).getCurrentResourceTemplateUser(templateUsers, currentUser);
  const isUserArchitect = currentUser?.isArchitect ?? false;

  const canMaintainRecords = hasPermission(clientId, currentUser, Roles.TeamLead) || hasFormRole(ClientFormUserRole.Contributor, currentResourceUser);
  const canMaintainResources = canMaintainRecords || isUserArchitect;

  const canViewRecords = canMaintainRecords || hasFormRole(ClientFormUserRole.Viewer, currentResourceUser);

  return {
    isUserArchitect,
    canMaintainResources,
    canMaintainRecords,
    canViewRecords,
  };
};

const useResourcePermissions = (selectedResource: FormConfig | null | undefined) => {
  const [users, setUsers] = useState<ClientTemplateFormUser[]>([]);
  const [tags, setTags] = useState<ClientFormTag[]>([]);
  const currentClient = useCurrentClient((x) => x.value);
  const currentUser = useCurrentUser((x) => x.value);
  const { data: clientGroupings = [] } = useFetchActiveClientGroups();
  const [userResourcePermissions, setUserResourcePermissions] = useState<UserResourcePermissions>({
    canMaintainRecords: false,
    canViewRecords: false,
    canMaintainResources: false,
    isUserArchitect: false,
  });

  useEffect(() => {
    if (currentClient && selectedResource && selectedResource.type === FormType.Resource) {
      new ClientTemplateFormService(currentClient.id)
        .getUsers(selectedResource.id)
        .then((res) => {
          setUsers(
            res.data.flatMap((el) => {
              return el.users.map((user) => {
                return { ...user, ...user.user, role: el.role } as ClientTemplateFormUser;
              });
            }),
          );
          setTags(
            res.data.flatMap((el) =>
              el.tags.map((t) => ({
                ...t,
                role: el.role,
                users: clientGroupings.find((g) => g.id === t.id)?.users.map((u) => ({ ...u, role: el.role })) ?? [],
              })),
            ),
          );
        })
        .catch(() => setUsers([]));
    }
  }, [clientGroupings, currentClient, selectedResource]);

  useEffect(() => {
    if (currentUser && currentClient) {
      setUserResourcePermissions(getUserResourcePermissions(users, currentUser, currentClient?.id));
    }
  }, [currentClient, currentUser, users]);

  return {
    ...userResourcePermissions,
    users,
    setUsers,
    tags,
    setTags,
  };
};

export default useResourcePermissions;
